import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Vue3Marquee from 'vue3-marquee'

loadFonts()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createApp(App)
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(Vue3Marquee)
    .mount('#app')
