<template>
    <v-footer fixed class="rounded-t-xl">
      <v-card
        tile
        flat
        dark
        width="100%"
        height="2em"
        class="d-flex align-center justify-start"
      >
        2023 &copy;  Hilal Gemi Mühendislik
        <v-spacer></v-spacer>
        <v-btn router :to="'/admin'" size="small" variant="tonal">
          <span class="text-subtitle-1">Admin</span>
        </v-btn>
        <v-spacer></v-spacer>
        <p v-if="language == 'tr'">Tüm hakları saklıdır.</p>
        <p v-if="language == 'en'">All rights reserved.</p>
      </v-card>
    </v-footer>
</template>

<script>
  import { useBaseStore } from "@/store/baseStore";
  
  export default {
    name: 'AppFooter',
    data () {
      const baseStore = useBaseStore()
      return {
        language: baseStore.getLang,
      }
    }
  }
</script>