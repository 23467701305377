// Composables
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: '/admin',
    name: 'Login',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Login.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Products.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Settings.vue'),
  },
  {
    path: '/404',
    name: 'Error404',
    component: () => import(/* webpackChunkName: "home" */ '@/views/error/Error404View.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
