<template>
  <nav>
    <v-app-bar app flat collapse-on-scroll class="rounded-be-xl">
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer class="hidden-md-and-up"></v-spacer>
      
      <v-app-bar-title>
        <v-img src="/logo-nav.png" @click="this.$router.push('/')" style="cursor: pointer"></v-img>
      </v-app-bar-title>

      <v-btn router href="/#banner" prepend-icon="mdi-home-roof" class="hidden-md-and-down" size="small">
        <span v-if="language == 'tr'" class="text-subtitle-1">Anasayfa</span>
        <span v-if="language == 'en'" class="text-subtitle-1">Home</span>
      </v-btn>

      <v-btn router href="/#about" prepend-icon="mdi-information-outline" class="hidden-md-and-down" size="small">
        <span v-if="language == 'tr'" class="text-subtitle-1">Hakkımızda</span>
        <span v-if="language == 'en'" class="text-subtitle-1">About</span>
      </v-btn>

      <v-btn router href="/#gallery" prepend-icon="mdi-view-gallery-outline" class="hidden-md-and-down" size="small">
        <span v-if="language == 'tr'" class="text-subtitle-1">Galeri</span>
        <span v-if="language == 'en'" class="text-subtitle-1">Gallery</span>
      </v-btn>

      <v-btn router href="/#referances" prepend-icon="mdi-pillar" class="hidden-md-and-down" size="small">
        <span v-if="language == 'tr'" class="text-subtitle-1">Referanslarımız</span>
        <span v-if="language == 'en'" class="text-subtitle-1">Referances</span>
      </v-btn>

      <v-btn router href="/#marquee" prepend-icon="mdi-domain" class="hidden-md-and-down" size="small">
        <span v-if="language == 'tr'" class="text-subtitle-1">Firmalar</span>
        <span v-if="language == 'en'" class="text-subtitle-1">Companies</span>
      </v-btn>

      <v-btn router href="/#contact" prepend-icon="mdi-human-greeting-proximity" class="hidden-md-and-down" size="small">
        <span v-if="language == 'tr'" class="text-subtitle-1">Bize Ulaşın</span>
        <span v-if="language == 'en'" class="text-subtitle-1">Contact Us</span>
      </v-btn>

      <v-btn router :to="'/products'" prepend-icon="mdi-store-outline" class="hidden-md-and-down" size="small">
        <span v-if="language == 'tr'" class="text-subtitle-1">Ürünlerimiz</span>
        <span v-if="language == 'en'" class="text-subtitle-1">Products</span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn size="small" @click="changeLanguage" class="hidden-sm-and-down">
        <span class="text-subtitle-1">TR/EN</span>
      </v-btn>

      <span class="mr-2 mt-4">
        <v-switch v-if="!loggedIn" inset color="white" v-model="darkMode" @click="themeChange" prepend-icon="mdi-theme-light-dark"></v-switch>
      </span>

      <div class="text-center" v-if="loggedIn">
        <v-menu open-on-click :close-on-content-click="false" location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon="mdi-account-details" variant="outlined" color="primary" class="ml-4 mr-4"></v-btn>
          </template>

          <v-list>
            <v-list-subheader color="secondary" class="d-flex justify-center">{{ authStore.getUsername }}</v-list-subheader>
            <v-list-subheader color="secondary" class="d-flex justify-center">
              <v-switch inset color="white" v-model="darkMode" @click="themeChange" prepend-icon="mdi-theme-light-dark"></v-switch>            
            </v-list-subheader>
            <v-list-item>
              <v-btn router to="/settings" class="text-capitalize" prepend-icon="mdi-cog-outline" variant="outlined" color="primary" block>Ayarlar</v-btn>
            </v-list-item>
            <v-list-item>
              <v-dialog v-model="logoutDialog" width="auto">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" append-icon="mdi-logout-variant" color="error" class="text-capitalize" block>Çıkış Yap</v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5 text-center">Çıkış Yap</v-card-title>
                  <v-card-text>Çıkış yapmak istediğinize emin misiniz?</v-card-text>
                  <v-card-actions class="justify-space-between">
                    <v-btn color="secondary" class="text-capitalize" variant="text" @click="logoutDialog = false">İptal</v-btn>
                    <v-btn router to="/admin" color="error" class="text-capitalize" variant="text" @click="logoutDialog = false">Çıkış Yap</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- Mobile Menu -->
    <v-navigation-drawer app v-model="drawer">
      <v-list>
        <v-list-subheader color="secondary" class="d-flex justify-center">
          <v-btn block flat variant="tonal" @click="changeLanguage">
            <span class="text-subtitle-1">TR/EN</span>
          </v-btn>
        </v-list-subheader>
        <v-list-item class="justify-space-between" router to="/">
          <template v-slot:prepend>
            <v-icon icon="mdi-home-roof" color="primary"></v-icon>
          </template>
          <v-list-item-title v-if="language == 'tr'">Anasayfa</v-list-item-title>
          <v-list-item-title v-if="language == 'en'">Home</v-list-item-title>
        </v-list-item>
        <v-list-item class="justify-space-between" router to="/products">
          <template v-slot:prepend>
            <v-icon icon="mdi-store-outline" color="primary"></v-icon>
          </template>
          <v-list-item-title v-if="language == 'tr'">Ürünlerimiz</v-list-item-title>
          <v-list-item-title v-if="language == 'en'">Products</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
  import { useTheme } from 'vuetify'
  import { ref } from 'vue'
  import { useAuthStore } from "@/store/authStore";
  import { useBaseStore } from "@/store/baseStore";

  export default {
    name: 'AppNavbar',
    setup(){
      const theme = useTheme()
      const authStore = useAuthStore()
      const baseStore = useBaseStore()

      theme.global.name.value = baseStore.getTheme

      return {
        theme,
        themeChange () {
          theme.global.current.value.dark ? baseStore.setTheme('customLightTheme') : baseStore.setTheme('customDarkTheme')
          theme.global.name.value = baseStore.getTheme
        },
        darkMode: ref(theme.global.current.value.dark ? true : false),
        authStore
      }
    },
    data () {
      const authStore = useAuthStore()
      const baseStore = useBaseStore()

      return {
        loggedIn: authStore.isLoggedIn,
        language: baseStore.getLang,
        drawer: false,
        logoutDialog: false,
      }
    },
    methods: {
      changeLanguage () {
        const baseStore = useBaseStore()
        var lang = this.language == 'en' ? 'tr' : 'en'
        baseStore.setLang(lang)
        this.language = baseStore.getLang
      }
    },
    watch: {
      language() {
        location.reload()
      },
    },
  }
</script>