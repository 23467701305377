// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify
import { createVuetify } from 'vuetify'

const customLightTheme = {
  dark: false,
  colors: {
    primary: '#423A8E',
    'primary-darken-1': '#423A8E',
    secondary: '#0D6EFD',
    'secondary-darken-1': '#0D6EFD',
    error: '#AB2E3C',
    info: '#00CCCD',
    success: '#2a9d8f',
    warning: '#Ee730c',
  }
}

const customDarkTheme = {
  dark: true,
  colors: {
    primary: '#0D6EFD',
    'primary-darken-1': '#0D6EFD',
    secondary: '#AF1763',
    'secondary-darken-1': '#AF1763',
    error: '#DC3545',
    info: '#0DCAF0',
    success: '#80ED99',
    warning: '#FFC107',
  }
}

export default createVuetify({
    theme: {
      defaultTheme: 'customLightTheme',
      themes: {
        customLightTheme,
        customDarkTheme,
      }
    },
    components,
    directives,
    
  }
)
