import { defineStore } from "pinia";

export const useAuthStore = defineStore('authStore', {
    state: () => {
        return {
            userId: 0,
            username: '',
            authToken: '',
            loggedIn: false,
        }
    },
    getters: {
        getUserId(state) {
            return state.userId
        },
        getUsername(state) {
            return state.username
        },
        getAuthToken(state) {
            return state.authToken
        },
        isLoggedIn(state) {
            return state.loggedIn
        }
    },
    actions: {
        setUsername(username) {
            this.username = username
        },
        login(id, username, token) {
            this.userId = id
            this.username = username
            this.authToken = token
            this.loggedIn = true
        },
        logout() {
            this.userId = ''
            this.username = ''
            this.authToken = ''
            this.loggedIn = false
        },
    },
    persist: true,
})