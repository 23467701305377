import { defineStore } from "pinia";

export const useBaseStore = defineStore('baseStore', {
    state: () => {
        return {
            theme: 'customLightTheme',
            lang: 'tr',
        }
    },
    getters: {
        getTheme(state) {
            return state.theme
        },
        getLang(state) {
            return state.lang
        },
    },
    actions: {
        setTheme(val) {
            this.theme = val
        },
        setLang(val) {
            this.lang = val
        },
    },
    persist: true,
})