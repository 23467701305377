<template>
    <v-overlay
      :model-value="true"
      class="align-center justify-center"
      persistent
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
  export default {
    name: 'AppLoading',
  }
</script>