<template>
  <v-app>
    <Navbar v-if="$route.name != 'Login'"/>
    <v-main class="mx-2 my-2">
      <Loading v-if="requestStore.isLoading"/>
      <router-view/>
    </v-main>
    <Footer v-if="$route.name != 'Login'"/>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import Loading from '@/components/Loading'
import { useAuthStore } from "@/store/authStore";
import { useRequestStore } from "@/store/requestStore";
import { useBaseStore } from "@/store/baseStore";


export default {
  name: 'App',

  components: {
    Navbar,
    Footer,
    Loading,
  },

  setup(){
    const authStore = useAuthStore()
    const requestStore = useRequestStore()
    const baseStore = useBaseStore()

    return {
      authStore,
      requestStore,
      baseStore
    }
  },

  data: () => ({
    //
  }),
}
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
  #app{
    font-family: Roboto;
  }
</style>
