import { defineStore } from "pinia";

export const useRequestStore = defineStore('requestStore', {
    state: () => {
        return {
            loading: false,
        }
    },
    getters: {
        isLoading(state) {
            return state.loading
        }
    },
    actions: {
        setLoading(loading) {
            this.loading = loading
        },
    },
    // persist: true,
})